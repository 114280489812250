import React from "react";

function Address() {
  return (
    <svg
      className="w-full h-full fill-current"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 300 300"
    >
      <path d="M204.9 202.8c-3.6 6.3-7.5 12.3-11.4 18 25.2 5.7 40.2 15.6 40.2 23.1 0 11.1-33.3 27.9-83.7 27.9S66.3 255 66.3 243.9c0-7.5 15-17.1 40.2-23.1-3.9-5.7-7.8-11.7-11.4-18-28.8 7.5-49.5 21-49.5 41.1 0 31.8 52.5 48.6 104.4 48.6s104.4-16.8 104.4-48.6c0-20.1-20.7-33.6-49.5-41.1zm-45.6 32.7c21.3-31.5 72.3-110.4 72.3-146.1 0-45.3-36.6-81.9-81.6-81.9S68.1 44.1 68.1 89.4c0 35.7 51.3 114.6 72.6 146.1 4.5 6.3 14.1 6.3 18.6 0zM111.9 89.4c0-21 17.1-38.1 38.1-38.1s38.1 17.1 38.1 38.1-17.1 37.8-38.1 37.8-38.1-17.1-38.1-37.8z"></path>
    </svg>
  );
}

export default Address;
