import React from 'react'
import { Link } from 'react-scroll'

const MenuItem = ({ title, link, offset }) => (
  <Link
    to={link}
    offset={offset ? offset : 0}
    smooth={true}
    duration={500}
    className="inline-block tracking-wide text-white no-underline transition cursor-pointer hover:opacity-50 focus:opacity-50"
  >
    {title}
  </Link>
)

const Menu = () => (
  <ul className="flex list-reset lg:-mx-6">
    <li className="lg:px-6">
      <MenuItem title="Our Services" link="treatment" offset={64} />
    </li>
    <li className="lg:px-6">
      <MenuItem title="Appointments" link="appointments" />
    </li>
    <li className="lg:px-6">
      <MenuItem title="About" link="about" />
    </li>
    <li className="lg:px-6">
      <MenuItem title="Contact" link="contact" />
    </li>
  </ul>
)

export default Menu
