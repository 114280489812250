import PropTypes from "prop-types";
import React from "react";
import Fade from 'react-reveal/Fade';

import { Link } from 'react-scroll'
import Hero from "./hero";
import Menu from "./menu";
import Logomark from './SVG/logomark'

function Layout({ children }) {
  const currentYear = new Date().getFullYear()

  return (
    <div className="font-sans leading-normal bg-grey lg:text-lg">
      <header className="w-full h-32 font-sans text-white bg-navy">
        <div className="flex items-center justify-between w-full h-full px-6 lg:px-16">
          <Fade ssrReveal>
            <Logomark />
          </Fade>
          <Fade ssrReveal>
            <div className="items-center hidden lg:flex">

              <Menu />
              <a
                href="tel:036884771"
                className="inline-block px-6 py-2 ml-12 text-white no-underline transition border border-white rounded-full hover:bg-white hover:text-navy focus:bg-white focus:text-navy focus:outline-none"
              >
                Book Now
              </a>
            </div>
          </Fade>
        </div>
      </header>
      <Hero />

      <main>
        {children}
      </main>

      <footer className="flex justify-between px-6 pb-8 text-xs lg:px-16 text-grey-dark">
        <Fade ssrReveal>
          <div>© {currentYear} Paul Crowther Orthodontics</div>
          <div>🦷</div>
        </Fade>
      </footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
