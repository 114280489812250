import React from 'react'

const Logomark = () => (
  <svg  className="-mt-3" width="68" height="39" xmlns="http://www.w3.org/2000/svg">
    <g fill="#FFF" fillRule="evenodd">
      <path d="M15 9c2.012-2.816 4.863-5.08 8.048-6.626A24.317 24.317 0 0 1 33.5 0c3.633 0 7.21.828 10.452 2.374C47.137 3.92 49.988 6.24 52 9c-2.795-2.043-5.757-3.7-8.887-4.804A28.854 28.854 0 0 0 33.5 2.54c-3.242 0-6.54.552-9.67 1.656C20.7 5.301 17.74 6.957 15 9" />
      <path
        d="M1.769 14.34c-1.011 10.712 0 17.574 3.088 20.922 1.404 1.562 3.313 2.343 5.671 2.343 2.415 0 4.267-.781 5.671-2.343 3.088-3.348 4.1-10.21 3.088-20.923H1.77zM10.472 39c-2.751 0-4.997-.948-6.682-2.79C.421 32.528-.702 25.107.421 13.614L.477 13h20.046l.056.614c1.123 11.493 0 18.858-3.37 22.596-1.684 1.842-3.93 2.79-6.737 2.79z"
        fillRule="nonzero"
      />
      <path
        d="M22.75 18.78c4.066 10.06 8.075 15.792 12.367 17.365 1.976.731 4.01.619 6.155-.505 2.146-1.068 3.444-2.642 4.009-4.665 1.186-4.384-1.016-11.016-6.833-20.177L22.75 18.78zM37.601 38a8.064 8.064 0 0 1-2.992-.562c-4.744-1.798-9.092-7.924-13.383-18.771L21 18.105 38.957 9l.338.506c6.268 9.779 8.696 16.916 7.341 21.806-.677 2.417-2.258 4.328-4.743 5.564-1.468.73-2.88 1.124-4.292 1.124z"
        fillRule="nonzero"
      />
      <path
        d="M48.713 14.34c-1.011 10.712 0 17.574 3.088 20.922 1.404 1.562 3.313 2.343 5.67 2.343 2.415 0 4.268-.781 5.672-2.343 3.088-3.348 4.099-10.21 3.088-20.923H48.713zM57.472 39c-2.751 0-4.997-.948-6.682-2.79-3.369-3.682-4.492-11.103-3.369-22.596l.056-.614h20.046l.056.614c1.123 11.493 0 18.858-3.37 22.596-1.684 1.842-3.986 2.79-6.737 2.79z"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default Logomark
