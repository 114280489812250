import React from 'react'
import Slider from 'react-slick'
import { Element } from 'react-scroll'
import Fade from 'react-reveal/Fade';

import Layout from '../components/layout'
import SEO from '../components/seo'
import Form from '../components/form'
import Map from '../components/map'
import IconAddress from '../components/SVG/address'
import IconPhone from '../components/SVG/phone'
import IconFacebook from '../components/SVG/facebook'

import imgOne from '../images/one.jpg'
import imgTwo from '../images/two.jpg'
import imgThree from '../images/three.jpg'
import imgFour from '../images/four.jpg'
import imgFive from '../images/five.jpg'

class IndexPage extends React.Component {

  render() {
    var settings = {
      autoplay: true,
      dots: false,
      arrows: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
    }

    return (
      <Layout>
        <SEO title="Home" />
        <Element
          name="treatment"
          className="px-6 pt-32 lg:pt-48 bg-white lg:px-16 pb-32 -mt-16"
        >
          <Fade ssrReveal>
            <div className="lg:flex lg:-mx-6">
              <div className="lg:w-1/2 lg:px-6">
                <h3 className="mb-8 text-3xl font-medium leading-none text-navy lg:text-4xl xl:text-5xl">
                  Our Services
                </h3>
              </div>
              <div className="lg:w-1/2 lg:px-6">
                <p className="mb-8">
                  We provide orthodontic advice, diagnosis and treatment. We use
                  removable and functional appliances, fixed braces, clear braces
                  and clear aligners. We will arrange referrals to other dental
                  specialists if additional specialist care is required.
                </p>
                <p>
                  We always seek to provide appropriate and timely treatment to
                  suit your needs and requirements.
                </p>
              </div>
            </div>
          </Fade>
        </Element>
        <Element
          className="px-6 pb-16 bg-white lg:px-16 lg:pt-16 lg:pb-64"
          name="appointments"
        >
          <Fade ssrReveal>
            <div className="lg:flex lg:-mx-6">
              <div className="lg:w-1/2 lg:px-6">
                <h3 className="mb-8 text-3xl font-medium leading-none text-navy lg:text-4xl xl:text-5xl">
                  Appointments
                </h3>
              </div>
              <div className="lg:w-1/2 lg:px-6">
                <p>
                  Referrals are not necessary to make appointments so if you are
                  concerned about the appearance or function of your, or your
                  child’s teeth please feel free to phone us direct with your
                  enquiry. We aim to provide excellent service at competitive
                  rates and we continue to follow or patients for some years after
                  active treatment is finished at no extra cost. Flexible payment
                  terms are available interest free.
                </p>
              </div>
            </div>
          </Fade>
        </Element>

        <Fade ssrReveal>
          <Slider
            {...settings}
            className="lg:px-16 lg:w-2/3 lg:mx-auto lg:-mt-32"
          >
            <div className="flex w-full focus:outline-none">
              <img className="block w-full" src={imgOne} alt="Waiting room" />
            </div>
            <div className="flex w-full focus:outline-none">
              <img className="block w-full" src={imgTwo} alt="Front desk and reception area" />
            </div>
            <div className="flex w-full focus:outline-none">
              <img className="block w-full" src={imgThree} alt="Treatment room" />
            </div>
            <div className="flex w-full focus:outline-none">
              <img className="block w-full" src={imgFour} alt="Front desk and waiting area" />
            </div>
            <div className="flex w-full focus:outline-none">
              <img className="block w-full" src={imgFive} alt="Corridor" />
            </div>
          </Slider>
        </Fade>

        <Element
          className="px-6 pt-16 mb-16 lg:px-16 lg:pt-32 lg:mb-32"
          name="about"
        >
          <Fade ssrReveal>
            <div className="lg:flex lg:-mx-6">
              <div className="lg:w-1/2 lg:px-6">
                <h3 className="mb-8 text-3xl font-medium leading-none leading-tight text-navy lg:text-4xl xl:text-5xl">
                  About
                </h3>
              </div>
              <div className="lg:w-1/2 lg:px-6">
                <p className="mb-8 text-xl">
                  Paul Crowther MDS(Otago) registered specialist for over 25
                  years. Formerly part of the Silver Birches group we are now
                  located in new premises at 10A Elizabeth Street.
                </p>

                <p className="mb-8">
                  I practised as a general dentist for 10 years before returning
                  to Otago and completing my three years of orthodontic training
                  in 1996. Following this I was based in Christchurch for 14 years
                  working in private specialist practice in Merivale, visiting
                  Timaru part time as well as working at Christchurch Hospital as
                  a Cleft Lip and Palate orthodontist. Since 2010 I have been
                  based in Timaru and in recent years I have had a part time
                  position as a clinical teacher at the University of Otago
                  orthodontic training programme.
                </p>
                <p>
                  Our associate orthodontist Joseph Antoun visits part time from
                  Christchurch.
                </p>
              </div>
            </div>
          </Fade>
        </Element>
          <Fade ssrReveal>
          <div className="h-96 lg:h-128">
            <Map />
          </div>
          </Fade>
        <Element
          className="relative z-20 px-6 py-16 mb-16 bg-white lg:px-16 lg:-mt-24 lg:py-32"
          name="contact"
        >
            <div>
              <div className="lg:flex lg:-mx-6">
                <div className="lg:w-1/2 lg:px-6">
                <Fade ssrReveal>
                  <h3 className="mb-8 text-3xl font-medium leading-none text-navy lg:text-4xl xl:text-5xl lg:mb-16">
                    Contact
                  </h3>
                  <p className="w-4/5 mb-8">
                    If you have any questions or concerns please do not hesitate
                    to contact us by calling us or filling out the form.
                  </p>
                  <div className="my-12 lg:my-0">
                    <div className="flex mb-4">
                      <div className="w-5 h-5 mt-1 mr-6 text-navy">
                        <IconPhone />
                      </div>
                      <div>
                      <a
                        className="mb-4 text-lg no-underline transition lg:text-xl text-navy focus:opacity-50 hover:opacity-50"
                        href="tel:036884771"
                      >
                        (03) 688 4771
                      </a>
                      </div>
                    </div>
                    <div className="flex mb-4">
                      <div className="w-5 h-5 mt-1 mr-6 text-navy">
                        <IconAddress />
                      </div>
                      <div>
                        <a
                          className="mb-4 text-lg no-underline transition lg:text-xl text-navy focus:opacity-50 hover:opacity-50"
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://goo.gl/maps/EL97yoYF35fpgcGd8"
                        >
                          10a Elizabeth Street
                          <br />
                          Timaru Central
                        </a>
                      </div>
                    </div>
                    <div className="flex mb-4">
                      <div className="w-5 h-5 mt-1 mr-6 text-navy">
                        <IconFacebook />
                      </div>
                      <div>
                        <a
                          className="mb-4 text-lg no-underline transition lg:text-xl text-navy focus:opacity-50 hover:opacity-50"
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.facebook.com/Paul-Crowther-Orthodontics-101585681534697"
                        >
                          Follow us on Facebook
                        </a>
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
              <div className="lg:w-1/2 lg:px-6">
                <Fade ssrReveal delay={300}>
                  <Form />
                </Fade>
              </div>
            </div>
          </div>
        </Element>
      </Layout>
    )
  }
}

export default IndexPage
