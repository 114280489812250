import React from 'react'
import axios from 'axios'
import { withFormik, Form, Field } from 'formik'

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const DefaultForm = ({status, errors }) => (
  <Form className="mb-8" name="contact"
  data-netlify="true"
  data-netlify-honeypot="bot-field">
    {/* Error Message */}
    {errors.submit && <div className="text-red mb-8">{errors.submit}</div>}
    {/* Success Message */}
    {status &&
      status.success && (
        <div className="text-navy mb-8">Thanks for your message, we will be in touch with you soon!</div>
      )}
      <Field type="hidden" name="bot-field" />
    <div className="mb-8 w-full">
      <label htmlFor="name" className="block text-navy uppercase text-xs font-medium">
        Name
      </label>
      <Field
        id="name"
        type="text"
        name="name"
        placeholder="Enter Name"
        className="border-b border-navy bg-transparent rounded-none py-2 w-full focus:outline-none"
        required
      />
    </div>
    <div className="mb-8 w-full">
      <label htmlFor="email" className="block text-navy uppercase text-xs font-medium">
        Email
      </label>
      <Field
        id="email"
        type="email"
        name="email"
        placeholder="Enter Email"
        className="border-b border-navy bg-transparent rounded-none py-2 w-full focus:outline-none"
        required
      />
    </div>
    <div className="mb-8 w-full">
      <label htmlFor="phone" className="block text-navy uppercase text-xs font-medium">
        Phone Number
      </label>
      <Field
        id="phone"
        type="tel"
        name="phone"
        placeholder="Enter Phone Number"
        className="border-b border-navy bg-transparent rounded-none py-2 w-full focus:outline-none"
        required
      />
    </div>

    <div className="mb-8 w-full">
      <label htmlFor="message" className="block text-navy uppercase text-xs font-medium">
        Message
      </label>
      <Field
        id="message"
        component="textarea"
        name="message"
        placeholder="Enter Message"
        className="border-b border-navy bg-transparent rounded-none py-2 h-32 w-full focus:outline-none"
        required
      />
    </div>
    <button
      type="submit"
      className="bg-transparent text-navy border border-navy hover:bg-navy focus:bg-navy hover:text-white focus:text-white font-normal py-2 px-8 rounded-full transition focus:outline-none"
    >
      Send
    </button>
  </Form>
)

const FormikForm = withFormik({
  mapPropsToValues: values => ({
    name: values.name || '',
    phone: values.phone || '',
    email: values.email || '',
    message: values.message || '',
  }),
  validate: values => {
    const errors = {}
    return errors
  },
  handleSubmit: (form, { resetForm, setErrors, setStatus, setSubmitting }) => {
    const convertedFormData = {
      ...form,
    }
    axios
      .post(
        // URL
        '?no-cache=1',
        // Data
        encode({
          'form-name': 'contact',
          ...convertedFormData,
        }),
        // Header
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      )
      .then(() => {
        resetForm()
        setStatus({ success: true })
      })
      .catch(error => {
        // Set errors.submit message
        console.log(error)
        setErrors({
          submit: 'There was an error submitting the form, please try again.',
        })
        // Erasing error message after 5s
        setTimeout(() => {
          setErrors({ submit: '' })
        }, 5000)
      })
      .finally(() => {
        setSubmitting(false)
      })
  },
})(DefaultForm)

export default FormikForm
