import React from 'react'
import { Link } from 'react-scroll'
import Fade from 'react-reveal/Fade';

import img from '../images/hero.jpg'

const Hero = () => {
  return (
    <div className="bg-navy text-white">
      <div className="lg:pl-16">
        <div className="lg:flex lg:-ml-24">
          <div className="flex-none pb-12 pl-6 lg:pl-24">
            <div className="lg:pb-24 lg:pt-12 xl:py-32">
              <Fade ssrReveal delay={300}>
                <h1 className="leading-tight mb-8 text-4xl lg:text-5xl font-medium">
                  Welcome to
                  <br />
                  Paul Crowther <br className="hidden lg:block" />
                  Orthodontics.
                </h1>
                <h4 className="mb-8 font-normal text-xl font-normal">
                  Timaru’s only Specialist Orthodontist.
                </h4>
                <Link
                  smooth={true}
                  duration={500}
                  offset={64}
                  to="treatment"
                  className="inline-block text-white items-center no-underline cursor-pointer hover:opacity-50 focus:opacity-50 transition mt-8"
                >
                  <div className="flex">
                    <span className="border h-7 w-7 mr-4 p-2 rounded-full flex justify-center items-center">
                      <svg
                        className="w-full h-full stroke-current"
                        viewBox="0 0 16 9"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M1 1l7 7 7-7" strokeWidth="1.5" fill="none" />
                      </svg>
                    </span>
                    Learn More
                  </div>
                </Link>
              </Fade>
            </div>
          </div>
          <Fade ssrReveal delay={600}>
            <div className="lg:pl-24 lg:pl-48 z-20 w-full">
              <div className="h-full flex">
                <img
                  className="block object-cover w-full"
                  src={img}
                  alt="Treatment room"
                />
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  )
}

export default Hero
