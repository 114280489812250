import React from "react";

function Phone() {
  return (
    <svg
      className="fill-current w-full h-full"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 300 300"
    >
      <path d="M240.9 239.1l-39-41.4c-5.4-5.7-14.4-6.9-21-2.7l-25.2 16.2c-13.8-15.3-23.7-28.5-33.3-48.6-10.5-21.6-14.1-37.8-17.1-57.3l28.2-9.6c7.5-2.4 12.3-10.2 11.1-18l-7.8-55.8c-1.5-9.9-11.1-16.2-20.7-13.8l-37.5 9.6c-8.7 2.1-15.6 9-18 17.4-2.4 8.7-5.1 20.1-6 28.2-3 36.9 5.7 79.5 24.9 119.7s46.8 73.5 77.4 94.5c6.9 4.8 17.4 9.6 25.8 13.2 8.4 3.6 18 2.4 25.2-3.3l30.6-23.7c8.4-6 9.3-17.4 2.4-24.6z"></path>
    </svg>
  );
}

export default Phone;
